<template>
    <label v-if="label" :for="id" class="block text-darkgray mb-3">{{ label }}<span v-if="isRequired"
            class="text-secondary">*</span></label>
    <textarea :name="name" v-if="multiline" :id="id" :class="[fieldClass, inputClassNew]" :placeholder="placeholder"
        v-model="inputValue" :required="isRequired" :disabled="disabled" :row="rows"></textarea>
    <div v-else class="relative">

        <Icon v-if="icon" :name='showIcon' :class="[iconClass, inputType !== 'select' && inputClassNew, inputType === 'select' && 'pointer-events-none']" size="24" @click="togglePassword" />
        <select v-model="inputValue"  v-if="inputType === 'select'" :class="[fieldClass, inputClassNew, 'appearance-none']" :id="id" :disabled="disabled">
            <slot />
        </select>

        <label v-else-if="inputType === 'file'" :class="[hidden ? 'hidden' : '']" :for="id">
            <input :name="name" :id="id" type="file" class="hidden" @change="handleFile" :accept="accept" :multiple="multiple" :disabled="disabled" :hidden="hidden" >
            <label :for="id" v-if="lg" :class="['flex items-center justify-center gap-5 p-8 border border-dashed border-themegray rounded-xl bg-[#D3D3D3] bg-opacity-30', inputClassNew]">
                <Icon name='mdi:cloud-upload' class="text-[#C9C9C9]" size="70"/>
                <div class="text-themegray">
                    <h4 class="font-medium text-darkgray">Drag and Drop</h4>
                    <p class="text-sm">or <span class="text-secondary">browse</span> your file to upload</p>
                    <p class="text-xxs">Supported files {{ supportedFiles }}</p>
                    <p class="text-xxs">Maximun {{ supportedFileSize }} MB size allowed</p>
                </div>
            </label>
            <label :for="id" v-else :class="fieldClass">
                {{ fileName }}
            </label>
        </label>
        
        <input :name="name" v-else :id="id" :min="min" :type="inputType" :class="[fieldClass, inputClassNew]" :placeholder="placeholder"
            v-model="inputValue" :required="isRequired" :disabled="disabled" :hidden="hidden" :autofocus="autofocus" />
    </div>
    <span v-if="errorMessage" class="text-secondary text-sm mt-3">{{ errorMessage }}</span>
</template>

<script setup>
const inputValue = defineModel()
const errorMessage = ref("")
const emit = defineEmits(['files'])

const props = defineProps({
    supportedFiles:{
        type:String,
        default: "jpg,png,jpeg"
    },
    supportedFileSize:{
        type: Number,
        default: 1024 * 1024 * 5
    },
    name: {
        type: String,
        default: false,
        required: false
    },
    multiline: {
        type: Boolean,
        default: false
    },
    icon: {
        type: String,
        default: ""
    },
    type: {
        type: String,
        default: "text",
    },
    placeholder: {
        type: String,
        default: ""
    },
    label: {
        type: String,
        default: "",
        required: true
    },
    id: {
        type: String,
        default: "",
        required: true
    },
    passwordToggle: {
        type: Boolean,
        default: false
    },
    validationRules: {
        type: Object,
        default: () => ({})

    },
    isRequired: {
        type: Boolean,
        default: false
    },
    inputClassNew: {
        type: String,
        default: ""
    },
    lg: {
        type: Boolean,
        default: false
    },
    disabled:{
        type: Boolean,
        default: false
    },
    accept:{
        type: String,
        default: ""
    },
    multiple:{
        type: Boolean,
        default: false
    },
    hidden:{
        type: Boolean,
        default: false
    },
    rows: {
        type: Number,
        default: 3
    },
    autofocus:{
        type:Boolean,
        default:false
    },
    min:{
        type:String,
        default: ""
    }
});

const handleFile = (event) => {
    emit("files",event.target.files)
}


let fileName = ref("Upload File");
const fieldClass = "w-full px-5 py-3 outline outline-1 outline-[#E9E9E9] bg-[#F2F2F2] rounded-lg placeholder:font-light max-h-[250px] block disabled:text-gray-600";
const iconClass = "absolute right-5 top-1/2 -translate-y-1/2 text-themegray cursor-pointer";
const pass = reactive({
    isVisible: false,
    closeEye: "iconoir:eye-closed",
    openEye: "iconoir:eye-solid"
})
const showIcon = ref((props.type == "password") ? pass.openEye : props.icon)
let inputType = ref(props.type)


const togglePassword = () => {
    if (props.type == "password") {

        if (!pass.isVisible) {
            inputType = "text"
            pass.isVisible = true
            showIcon.value = pass.closeEye
        }
        else {
            showIcon.value = pass.openEye
            pass.isVisible = false
            inputType = "password"
        }

    }

}

</script>